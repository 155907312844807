/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-end-btn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 12V4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2m6.79-6.907A.5.5 0 006 5.5v5a.5.5 0 00.79.407L9.5 8.972V10.5a.5.5 0 001 0v-5a.5.5 0 00-1 0v1.528z"/>',
    },
});
